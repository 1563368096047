export const metaData = {
  title: "ok.systems",
  description: "",
  openGraph: {
    type: "website",
    locale: "en_US",
    url: "https://ok.systems",
    site_name: "ok.systems",
    images: [
      {
        url: '/ok-share-image.png',
        width: 1200,
        height: 630,
        alt: '',
      },
    ],
  },
  twitter: {
    handle: "@SystemsOk",
    site: "@SystemsOk",
    cardType: "summary_large_image",
  },
};

export const analytics = {
  id: "UA-155263594-1",
};

export const quarterSize = `calc(1rem + 0.25vw)`;
export const baseSize = `calc(1rem + 2vw)`;
export const halfSize = `calc(1rem + 0.5vw)`;
export const doubleSize = `calc(1rem + 4vw)`;
export const bigSize = `calc(1rem + 6vw)`;
export const megaSize = `calc(1rem + 14vw)`;
