import { DefaultSeo } from "next-seo";
import Router from "next/router";
// import withGA from "next-ga";
import Head from "next/head";

import "../styles/globals.css";
import { metaData, analytics } from "@/lib/settings";

function MyApp({ Component, pageProps, router }) {
  return (
    <>
      <Head>
        <meta name="application-name" content={metaData.title}/>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content={metaData.title} />
        <meta name="description" content={metaData.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="theme-color" content="#000000" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <link rel="shortcut icon" href="/favicon.ico" />

      </Head>
      <DefaultSeo {...metaData} />
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
